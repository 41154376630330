const fr = {
  bulk: {
    explanation: {
      paragraph1:
        'Les mises à jour en masse des codes peuvent être effectuées en téléchargeant un fichier CSV dans le format: <1>NIP, Code Postal, Prix;</1>',
      paragraph2:
        '<0>Les codes postaux</0> sont nécessaires pour rendre les NIPs <3>réservés</3>.',
      paragraph3:
        '<0>Les prix</0> types (20 ou 10) sont nécessaires pour rendre les NIPs <3>utilisés</3>.',
      paragraph4: 'Par exemple:  <1>AB BB BB B2, M2R 1B1, 20</1>',
    },
    followingUpdated: 'Les code(s) NIP suivants ont été mis à jour.',
    lockMark:
      '{{count}} code(s) NIP seront mis à jour et marqués comme verrouillés.',
    markLocked: 'Marquer tous les codes NIP comme <1>verrouillés.</1>',
    markReleased:
      'Libérer et marquer tous les codes NIP comme <1>disponibles</1> à nouveau.',
    markReserved: 'Marquer tous les codes NIP comme <1>réservés.</1>',
    markUsed: 'Marquer tous les codes NIP comme <1>utilisés.</1>',
    releaseMark:
      '{{count}} code(s) NIP seront mis à jour et marqués comme disponibles à nouveau.',
    reserveMark:
      '{{count}} code(s) NIP seront mis à jour et marqués comme réservés.',
    uploadDifferent: 'Télécharger un autre fichier csv',
    useMark:
      '{{count}} code(s) NIP seront mis à jour et marqués comme utilisés.',
    willNotBeUpdated: '{{count}} code(s) NIP ne seront pas mis à jour.',
  },
  buttons: {
    addComment: 'Ajouter une note',
    addCsv: 'Ajouter le fichier CSV pour télécharger',
    changePrice: 'Changer pour {{price}}',
    completeInstallation10: 'Marquer ce NIP comme Utilisé pour 10 $ / mois',
    completeInstallation20: 'Marquer ce NIP comme Utilisé pour 20 $ / mois',
    lock: 'Verrouiller',
    lookUpPinCode: 'Vérifier le code NIP',
    release: 'Libérer',
    signOut: 'Déconnexion',
    unlock: 'Déverrouiller',
    updateAndContinue: 'Mettre à jour et continuer',
    uploadFile: 'Télécharger le fichier',
    validate: 'Valider',
    submit: 'Soumettre',
  },
  comment: {
    dateFormat: 'D MMMM YYYY, H [h] mm',
    tooLong: 'Le commentaire est trop long.',
  },
  errors: {
    expiresAtDateFormat: 'D MMMM YYYY',
    invalidExpiredOn: 'Le code NIP expiré {{date}}.',
    invalidIsp: 'Le code NIP déjà utilisé par un autre FSI.',
    invalidPinNotFound: 'Le code NIP inexistant.',
    invalidPinPrefix: 'Le code NIP devrait commencer avec un {{prefixes}}.',
    invalidPinTooLong: 'Le code NIP trop long. Les code NIP ont 8 caractères.',
    invalidPinTooShort:
      'Le code NIP trop court. Les code NIP ont 8 caractères.',
    invalidPrice: 'Le prix fourni n’est pas valide.',
    invalidStatus:
      'Le statut du code NIP est présentement “{{badStatus}}”, et non “{{goodStatuses}}”.',
    invalidStatusNoApplication:
      'Le code PIN n’a pas encore été validé. Veuillez diriger le demandeur vers le portail.',
    invalidVerification:
      'Le code postal ne correspond pas au code NIP. Assistez le postulant à inscrire la bonne adresse dans le portail.',
    limitReached:
      'Ce code PIN a déjà été utilisé pour commander un ordinateur gratuit.',
    unexpectedError: `Une erreur imprévue s’est produite.`,
    noAccess: 'No Access', //todo: translate
    noIsp: 'Aucun FSI n’est disponible dans la zone de service du demandeur.',
  },
  formValidation: {
    pinRequired: 'Le code NIP est requis.',
    postalCodeInvalid: 'Le code postal n’est pas valide.',
    postalCodeRequired: 'Le code postal est requise.',
  },
  general: {
    connectingFamilies: 'Familles branchées',
    disclaimer:
      'Ceci est un site test. Veuillez utiliser <1>production<1> pour clients',
    loading: 'Chargement',
    no: 'Non',
    postalCode: 'Code Postal',
    yes: 'Oui',
  },
  labels: {
    enterAddress: 'Entrer le code postal du postulant pour valider le code NIP',
    enterPinCode: 'Entrer le code NIP du postulant',
  },
  links: {
    connectingFamiliesAdmin: 'https://admin.cf-fb.org',
  },
  list: {
    quotationOpener: '«',
    quotationCloser: '»',
    separator: ', ',
    disjunction: ' ou ',
  },
  pinInvalidReasons: {},
  pinMessages: {
    alreadyReserved: 'Le code NIP a déjà été réservé.',
    cancelInstallation:
      'En annulant une installation libérer le code NIP pour qu’il redevienne disponible.',
    confirmComplete:
      'Marquez le statut de ce NIP comme utilisé <0>seulement</0> après que l’internet à 10 $ du client a été activé et mis en facturation.',
    confirmInstallation:
      '<0>Veuillez confirmer :</0> Marquer le statut de ce NIP comme <2>Usagé</2> uniquement après que le  <4>{{price}}</4> Internet de ce client ait été activé et inscrit à la facturation',
    currentPrice:
      'Ce NIP est présentement marqué à un plan maximum de <2>{{price}}</2> plan.',
    ifDisconnectedDueToNonPayment: `Un compte s'étant fait débrancher Internet pour retard de paiement peut être verrouillé.`,
    locked: 'Le code NIP a été verrouillé.',
    maximumReached: 'Vous avez dépassé le nombre maximum de codes NIP.',
    maximumReachedWithTitle:
      '{{isp}} a atteint sa capacité maximale pour les NIP utilisés.',
    noCompleteInstallation: 'Non, un technicien est attendu',
    pinHasBeenUsed:
      'Le NIP a été marqué comme utilisé et l’installation est complétée.',
    pinHasBeenUsedWithPrice:
      'Le NIP été marqué comme <1>Utilisé</1> pour l’Internet à <3>{{price}}</3> et l’installation est complétée.',
    pleaseMarkComplete:
      'Marquer le statut de ce NIP comme Utilisé <1>seulement</1> lorsque l’Internet de ce client a été activé et inscrit à la facturation',
    released: 'Le code NIP a été libéré et est maintenant disponible.',
    switchPriceType:
      'Le code NIP est toujours utilisé mais le prix a changé pour {{price}}',
    unlocking: 'Déverrouiller un code NIP changera le statut à utilisé.',
    validAndReserved:
      'Le code NIP est valide et a été réservé pour utilisation.',
    yesCompleteInstallation: 'Oui, marquer ce NIP comme Utilisé pour {{price}}',
  },
  pinStatus: {
    available: 'Disponible',
    locked: 'Verrouillé',
    reserved: 'Réservé',
    used: 'Utilisé',
    noIsp: 'pas de FSI',
  },
  prices: {
    tenDollar: '10 $ / mois',
    twentyDollar: '20 $ / mois',
  },
  reminders: {
    paragraph1:
      'Si leur code a 9 caractères, <2>veuillez entrer uniquement les 8 premiers caractères</2>.',
    paragraph2:
      'Si leur code a 8 caractères, veuillez entrer tous les caractères.',
  },
  affiliatesTool: {
    title: 'Outil affiliél',
    enterPin: 'Entrer un code NIP',
    noApplicationFound: 'Aucune application trouvée',
    pinReport: 'Rapport sur le NIP',
    mailingAddress: 'Adresse postale',
    mailingAddressExplained: ' L’adresse où le bénéficiaire a reçu la lettre.',
    serviceAddress: 'Adresse de service',
    serviceAddressExplained:
      'L’adresse où le bénéficiaire est connecté à Internet.',
    lineOne: 'Adresse',
    lineTwo: 'Appartement, suite, unité, immeuble, étage, etc',
    city: 'Ville',
    province: 'Province',
    postalCode: 'Code postal',
    description:
      'Utilisez cet outil pour confirmer que le demandeur est un bénéficiaire.',
  },
  navigation: {
    enterNewPin: 'Entrer un nouveau code NIP',
    updatePin: 'Mise à jour NIP',
    uploadCsvFile: 'Télécharger le fichier csv',
    pinTool: 'Outil historique NIP',
    pinLookup: 'Outil de recherche NIP',
    affiliatesTool: 'Outil affilié',
  },
};

export default fr;
